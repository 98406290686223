import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./../../scss/global.scss"
import "./features.scss"
import img from "../../assets/img/screens/add-task.webp"

const PlanTrackSummarizePage = ({ data }: any) => (
  <Layout>
    <SEO
      title="Super Productivity – a to do list to get your head free"
      description="Made for developer & freelancer, Super Productivity helps to structure, organize & share your work. Learn more about features like time tracking & Jira connect."
    />

    <div className="container">
      <article>
        <h1>Plan, Track & Summarize</h1>

        <h2>Let time track itself</h2>
        <p>
          Keeping track of the time spent on a task or project is essential for
          everyone but especially for freelancers. With our App you can estimate
          the time you will spend on a tasks and the time tracker will show you
          how much time you actually invested. If it does not work out the first
          time, don&apos;t worry Rome wasn&apos;t built in a day either so, just
          try again.
        </p>

        <img src={img} className="float-img-left" alt="Tracking time" />

        <h2>Try – fail – learn – repeat</h2>

        <p>
          Knowing how much time you’ve been spending on a project in the past
          makes it easier to estimate the next one. That helps you in your
          communication with a client when you are working on a project but it
          also allows you to plan your personal life so you don&apos;t have to
          cancel any dinners or dates because you are running out of time.
        </p>

        <h2>Value your time</h2>

        <p>
          The time tracker already makes it easy for you to track your work and
          export it to your clients time tracking system but there is more. Once
          you have gathered some data on your working habits and time spent on
          projects, you can give better estimation to clients which will improve
          the satisfaction with your work. Besides having a way to calculate
          your work rates, time estimation is helping you to understand if a
          project is valuable. Little is less satisfying than working on a
          project and realizing you are investing too much for too little.
        </p>
      </article>
    </div>
  </Layout>
)

export default PlanTrackSummarizePage
